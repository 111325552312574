import React from 'react';

import styles from './ABSplitField.module.scss';
import { useFormContext } from '../../hooks/useForm';
import FieldWrapper from '../Form/FieldWrapper';
import Input from '../Form/Input';

function ABSplitField({ defaultSplit }) {
  const { formData, setValue, errors, register } = useFormContext();
  return (<div>
    <div className={styles.fieldsContainer}>
      <Input type='text' name='variant_a_name' defaultValue='' label='Variant A Name' placeholder='Name for Variant A' />
      <FieldWrapper errorMessage={errors['a_b_split']}>
        <label htmlFor='a_b_split'>Probability for Variant A</label>
        <div className={styles.inputContainer}>
          <div className={styles.numberContainer}><input type='number' id='a_b_split' name='a_b_split' {...register('a_b_split', defaultSplit)}
            onChange={(e) => setValue('a_b_split', e.target.value)}
            step='1'
          />
          </div>
          <span className={styles.unitContainer}>%</span>
        </div>
      </FieldWrapper>
    </div>
    <div className={styles.fieldsContainer}>
      <Input type='text' name='variant_b_name' defaultValue='' label='Variant B Name' placeholder='Name for Variant B' />
      <FieldWrapper>
        <label htmlFor='b_split'>Probability for Variant B</label>
        <div className={styles.inputContainer}>
          <div className={styles.numberContainer}><input type='number' id='b_split' name='b_split' 
            {...register('b_split', 100-defaultSplit)}
            value={formData['a_b_split'] === '' || formData['a_b_split'] === undefined ? '' : 100-formData['a_b_split']}
            onChange={(e) => e.target.value === '' ? setValue('a_b_split', '') : setValue('a_b_split', 100-e.target.value)}
            step='1'
          />
          </div>
          <span className={styles.unitContainer}>%</span>
        </div>
      </FieldWrapper>
    </div>
  </div>);
}

export default ABSplitField;
