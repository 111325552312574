import React from 'react';

import { Dialog } from '@headlessui/react';

import styles from './ModalPopUp.module.scss';

const ModalPopUp = ({ isOpen, onClose, title, description, disabled, children }) => {
  return (
    <Dialog open={isOpen} 
      className={`${styles.modalOverlay} ${disabled ? styles.disabled : ''}`}
      onClose={() => {
        if (!disabled) onClose();
      }}
    >
      <Dialog.Panel className={styles.modal}>
        {title && <Dialog.Title className={styles.modalTitle}>{title}</Dialog.Title>}
        {description && <Dialog.Description as='div' className={styles.modalDescription}>
          {description}
        </Dialog.Description>}
        {children}
      </Dialog.Panel>
    </Dialog>
  );
};

export default ModalPopUp;
