import React from 'react';

import {
  faPaperPlane, faChartSimple,
  faBriefcase, faUsers, faLink, faUpload, faIdCard,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useLocation } from 'react-router-dom';

import styles from './Sidebar.module.scss';
// import settingsIcon from '../../assets/images/menu-settings.png';
import { VERSION_NUMBER, PAPERPLANES_ID } from '../../config';
import { usePermissions } from '../../hooks/usePermissions';

function TabIcon({ url, name, icon, display, selectedIcon }) {
  return (<>
    {display && <div className={styles.icon}>
      <Link to={url} className={styles.menuItem}>
        <FontAwesomeIcon
          icon={icon} alt={name}
          className={selectedIcon === url ? styles.selected : styles.notSelected}
          size='xl'
        />
      </Link>
      <label>{name}</label>
    </div>}
  </>);
}

function Sidebar() {
  const { userPermissions } = usePermissions();
  const selectedIcon = useLocation().pathname;
  const isAdmin = userPermissions.role === 'Admin';
  const isPPAccount = userPermissions.companyId === PAPERPLANES_ID;
  const displayDashboard = userPermissions.displayDashboard;

  return (
    <div className={styles.sidebar}>
      {/* Temporary until an icon is provided */}
      <TabIcon url='/campaigns' name='Campaigns' icon={faPaperPlane} display={isPPAccount} selectedIcon={selectedIcon} />
      <TabIcon url='/dashboard' name='Dashboard' icon={faChartSimple} display={displayDashboard} selectedIcon={selectedIcon} />
      <TabIcon url='/companies' name='Companies' icon={faBriefcase} display={isPPAccount} selectedIcon={selectedIcon} />
      <TabIcon url='/users' name='Users' icon={faUsers} display={isAdmin} selectedIcon={selectedIcon} />
      <TabIcon url='/upload' name='Uploads' icon={faUpload} display={true} selectedIcon={selectedIcon} />
      <TabIcon url='/link_keys' name='Connect' icon={faLink} display={isAdmin && !isPPAccount} selectedIcon={selectedIcon} />
      <TabIcon url='/customers' name='Customers' icon={faIdCard} display={isAdmin && !isPPAccount} selectedIcon={selectedIcon} />
      {/* <MenuItem to='/path-to-settings-page' icon={settingsIcon} alt='Settings'
        color={selectedIcon === '/path-to-settings-page' ? '#b7cbdc': '#000'}/> */}
      <footer className={styles.footerText}>Version {VERSION_NUMBER}</footer>
    </div>
  );
}

export default Sidebar;
