import React, { useState, useEffect, createContext, useContext } from 'react';

const FormContext = createContext(null);

function useForm(initialValues={}) {
  const [formData, setFormData] = useState(initialValues);
  const [defaultValues, setDefaultValues] = useState({});
  const [errors, setErrors] = useState({});

  const setValue = (field, value) => {
    setFormData({...formData, [field]: value});
    if (errors[field]) setErrors({...errors, [field]: null});
  };
  const clearErrors = () => setErrors({});
  const validate = (schema) => {
    const newErrors = {};
    for (const field of Object.keys(schema)) {
      const s = schema[field];
      const v = formData[field];
      if (s.required && v === '') {
        newErrors[field] = s.required;
        continue;
      }
      if (s.min != null && parseFloat(v) < s.min) {
        newErrors[field] = `Cannot be less than ${s.min}`;
        continue;
      }
      if (s.max != null && parseFloat(v) > s.max) {
        newErrors[field] = `Cannot be greater than ${s.max}`;
        continue;
      }
      if (s.custom) {
        if (!s.custom.func(v, formData)) {
          newErrors[field] = s.custom.message;
          continue;
        }
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const reset = (fieldsToReset=null) => {
    if (fieldsToReset) {
      // Reset only the specified fields
      const updatedFormData = { ...formData };
      fieldsToReset.forEach((field) => {
        if (field in defaultValues) {
          updatedFormData[field] = defaultValues[field];
        }
      });
      setFormData(updatedFormData);
    } else {
      // Reset all fields
      setFormData(defaultValues);
    }
  };

  const register = (field, defaultValue) => {
    useEffect(() => {
      console.log('register');
      if (formData[field] === undefined) {
        setFormData((prev) => ({ ...prev, [field]: defaultValue }));
        setDefaultValues((prev) => ({...prev, [field]: defaultValue}));
      }
    }, []);

    return { value: formData[field] === undefined ? '' : formData[field] };
  };

  return {
    formData, setFormData, reset, setValue,
    errors, clearErrors,
    validate, register,
  };
}

function FormProvider({ value, children }) {
  return <FormContext.Provider value={value}>{children}</FormContext.Provider>;
}

function useFormContext() {
  const context = useContext(FormContext);
  if (!context) {
    throw new Error('useFormContext must be used within a FormProvider');
  }
  return context;
}

export { useForm, FormProvider, useFormContext };

