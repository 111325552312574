import React from 'react';

import styles from './ToggledNumberField.module.scss';
import { useFormContext } from '../../hooks/useForm';
import Input from '../Form/Input';

function ToggledNumberField({ label, name, min, max, unit }) {
  const { formData } = useFormContext();
  return (<div>
    <label htmlFor={name}>{label}</label>
    <div className={styles.fieldsContainer}>
      <Input type='checkbox' name={`${name}_enabled`} />
      <Input type='number' disabled={!formData[`${name}_enabled`]} name={name} min={min} max={max} unit={unit} />
    </div>
  </div>);
}

export default ToggledNumberField;
