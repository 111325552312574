import React from 'react';

import styles from './Form.module.scss';

function Form({ id, onSubmit, children, className }) {
  const submit = (e) => {
    e.preventDefault();
    onSubmit();
  };

  return (<form id={id} onSubmit={submit} className={className || styles.form}>
    {children}
  </form>);
}

export default Form;
