import React, { useState, useEffect } from 'react';

import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { faCircleDot, faCircleCheck, faShoppingCart, faBell, faGift } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import CampaignSummary from './CampaignSummary';
import { KlaviyoForm, ABDForm, NBPForm, WinbackForm } from './CampaignTypeForms';
import styles from './CreateCampaign.module.scss';
import { campaigns, campaignFrequency } from './createCampaignEnums';
import klaviyoLogo from '../../assets/images/klaviyo-logo.svg';
import Form from '../../components/Form/Form';
import Input from '../../components/Form/Input';
import ABSplitField from '../../components/Inputs/ABSplitField';
import TiledRadioField from '../../components/Inputs/TiledRadioField';
import ToggleSwitch from '../../components/Inputs/ToggleSwitch';
import Spinner from '../../components/Spinner/Spinner';
import { PAPERPLANES_ID } from '../../config';
import { useForm, useFormContext, FormProvider } from '../../hooks/useForm';
import { usePermissions } from '../../hooks/usePermissions';
import CampaignService from '../../services/CampaignService';
import CompanyService from '../../services/CompanyService';

function useCompanyList() {
  const [companyOptions, setCompanyOptions] = useState([]);

  const loadCompanyList = async () => {
    const getCompanyListResult = await CompanyService.getCompanyList();
    if (getCompanyListResult.success) {
      const companies = getCompanyListResult.data.filter(
          (company) => company.status === 'Active',
      ).map((company) => {
        return {label: company.name, value: company.mIdsite};
      });
      setCompanyOptions(companies.sort((a, b) => {
        const labelA = a.label.toLowerCase();
        const labelB = b.label.toLowerCase();
        if (labelA < labelB) return -1;
        if (labelA > labelB) return 1;
        return 0;
      }));
    } else {
      toast.error('Failed to load companies. Please try again.');
      setCompanyOptions([{label: 'Could not load companies', value: ''}]);
    }
  };
  
  return {
    companyOptions,
    loadCompanyList,
  };
}

function useCreativeList() {
  const [letterPaths, setLetterPaths] = useState([]);
  const [envelopePaths, setEnvelopePaths] = useState([]);
  
  const loadLetterPaths = async (companyId) => {
    const getLetterPaths = await CompanyService.getCompanyLetterPaths(companyId);
    if (getLetterPaths.success) {
      setLetterPaths(getLetterPaths.data.map((letterPath, index) => {
        return {label: letterPath, value: letterPath, selected: index == 0};
      }));
    } else {
      console.log(getLetterPaths);
      toast.error('Failed to load company letter templates. Please try again');
    }
    return getLetterPaths.success;
  };

  const loadEnvelopePaths = async (companyId) => {
    const getEnvelopePaths = await CompanyService.getCompanyEnvelopePaths(companyId);
    if (getEnvelopePaths.success) {
      setEnvelopePaths(getEnvelopePaths.data.map((envelopePath, index) => {
        return {label: envelopePath, value: envelopePath, selected: index == 0};
      }));
    } else {
      toast.error('Failed to load company envelope templates. Please try again');
    }
    return getEnvelopePaths.success;
  };

  return {
    letterPaths, loadLetterPaths, envelopePaths, loadEnvelopePaths,
  };
}

function CreateCampaign() {
  const methods = useForm();
  const [currStep, setCurrStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const { companyOptions, loadCompanyList } = useCompanyList();
  const [mailableCount, setMailableCount] = useState(null);
  const { letterPaths, loadLetterPaths, envelopePaths, loadEnvelopePaths } = useCreativeList();
  const history = useHistory();

  const { userPermissions } = usePermissions();
  const isPPAccount = userPermissions.companyId === PAPERPLANES_ID;

  const stepLabels = ['Type', 'Settings', 'Additional Settings', 'Letter', 'Envelope', 'Campaign Controls', 'Summary'];

  const steps = [
    {
      label: 0, title: 'Select your campaign type',
      render: () => <CampaignTypeForm id='cf' onSubmit={handleNext} />,
    },
    {
      label: 1, title: 'Campaign Settings',
      render: () => <CampaignSettingsForm id='cf' companyOptions={companyOptions} onSubmit={handleNext} />,
    },
    {
      label: 2, title: 'Klaviyo Settings',
      render: () => <KlaviyoForm id='cf' onSubmit={handleNext} mailableCount={mailableCount} checkSegmentId={checkSegmentId} />,
      condition: (data) => data['campaign_type'] === campaigns.KLAVIYO.value,
    },
    {
      label: 2, title: 'Abandoned Basket Settings',
      render: () => <ABDForm id='cf' onSubmit={handleNext} />,
      condition: (data) => data['campaign_type'] === campaigns.ABD.value,
    },
    {
      label: 2, title: 'Next Best Purchase Settings',
      render: () => <NBPForm id='cf' onSubmit={handleNext} />,
      condition: (data) => data['campaign_type'] === campaigns.NBP.value,
    },
    {
      label: 2, title: 'Winback Settings',
      render: () => <WinbackForm id='cf' onSubmit={handleNext} />,
      condition: (data) => data['campaign_type'] === campaigns.WINBACK.value,
    },
    {
      label: 3, title: 'Choose Your Letter',
      render: () => <LetterPathForm id='cf' onSubmit={handleNext} letterPaths={letterPaths} />,
      execute: () => loadLetterPaths(methods.formData['company']),
    },
    {
      label: 4, title: 'Choose Your Envelope',
      render: () => <EnvelopePathForm id='cf' onSubmit={handleNext} envelopePaths={envelopePaths} />,
      execute: () => loadEnvelopePaths(methods.formData['company']),
    },
    {
      label: 5, title: 'Campaign Controls',
      render: () => <CampaignControlsForm id='cf' onSubmit={handleNext} />,
    },
    {
      label: 6, title: 'Summary',
      render: () => <CampaignSummary data={methods.formData} companies={companyOptions} 
        goToControls={() => goToStep(5)}
        goToSettings={() => goToStep(1)}
        goToCreatives={() => goToStep(3)} />,
      isStatic: true,
    },
  ];
  
  const checkSegmentId = async (segmentId) => {
    const companyId = isPPAccount ? methods.formData['company'] : userPermissions.companyId;
    setLoading(true);
    const response = await CampaignService.getMailableCustomers(companyId, segmentId);
    setLoading(false);
    if (response.success) setMailableCount(response.count);
    return response.success;
  };
  
  const handlePrev = () => {
    let index = currStep-1;
    while (index >= 0 && steps[index].condition && !steps[index].condition(methods.formData)) {
      index -= 1;
    }
    index < 0 ? close() : setCurrStep(index);
  };

  const handleNext = async () => {
    let index = currStep+1;
    while (index < steps.length && steps[index].condition && !steps[index].condition(methods.formData)) {
      index += 1;
    }
    console.log(index);
    if (index >= steps.length) {
      await createCampaign(methods.formData);
    } else {
      if (steps[index].execute) {
        setLoading(true);
        await steps[index].execute();
        setLoading(false);
      }
      setCurrStep(index);
    }
  };

  const goToStep = (step) => {
    const index = steps.findIndex((s) => s.label === step && (!s.condition || s.condition(methods.formData)));
    console.log(index);
    setCurrStep(index);
  };

  const createCampaign = async (data) => {
    setLoading(true);
    try {
      const submitData = {...data};
      if (!isPPAccount) submitData.company = userPermissions.companyId;
      submitData['is_one_time'] = submitData['is_one_time'] === campaignFrequency.ONE_TIME.value;
      if (!submitData['is_a_b_split']) submitData['a_b_split'] = 100;
      if (submitData['campaign_type'] !== campaigns.KLAVIYO.value) submitData['list_id'] = '0';
      if (!submitData['control_split_enabled']) submitData['control_split'] = 0;
      if (!submitData['suppression_enabled']) submitData['suppression_period'] = 0;
      if (!submitData['send_limit_enabled']) submitData['daily_send_limit'] = 0;
      submitData['data_source'] = submitData['campaign_type'] == campaigns.KLAVIYO.value ? 'klaviyo' : 'crm';
      if (submitData['campaign_type'] === campaigns.KLAVIYO.value) submitData['campaign_type'] = 'WIB';
      else if (submitData['campaign_type'] === campaigns.NBP.value) {
        submitData['campaign_type'] = submitData['strategy'];
      }
      
      const response = await CampaignService.createCampaign(submitData);
      if (response.success) {
        toast.success(`Campaign ${submitData['campaign_name']} successfully created`);
        history.push('/campaigns');
      } else {
        if (response.error && response.error.errors) {
          response.error.errors.map((err) => toast.error(err.detail));
        }
      }
    } catch (e) {
      toast.error('An error occured when creating the campaign');
    }
    setLoading(false);
  };

  useEffect(() => {
    if (isPPAccount) {
      const fetchData = async () => {
        setLoading(true);
        await loadCompanyList();
        setLoading(false);
      };
      fetchData();
    }
  }, []);

  return (<div className={styles.mainContainer}>
    {loading && <Spinner loading={true} />}
    <div className={`${styles.contentContainer} ${styles.flexContainer}`}>
      {/* Step labels */}
      <div className={styles.stepsContainer}>
        {stepLabels.map((step, index) => {
          let icon;
          const completed = index < steps[currStep].label;
          if (completed) icon = <FontAwesomeIcon icon={faCircleCheck} className={styles.completedIcon} />;
          else if (index > steps[currStep].label) icon = <FontAwesomeIcon icon={faCircle} className={styles.notCompletedIcon} />;
          else icon = <FontAwesomeIcon icon={faCircleDot} className={styles.currentIcon} />;
          return <div key={index}>
            <div className={styles.step}>
              {index > 0 && <div className={styles.stepLine} />}
              <div className={`${styles.stepContent} ${completed ? styles.clickable : ''}`} 
                onClick={() => completed ? goToStep(index) : null}>
                <div className={styles.stepIcon}>{icon}</div>
                <div className={styles.stepLabel}>{step}</div>
              </div>
            </div>
          </div>;
        })}
      </div>
      {/* Create campaign form */}
      <div className={`${styles.formContainer} ${loading ? styles.disabled : ''}`}>
        <h2>{steps[currStep].title}</h2>
        <div className={styles.formContent}>
          <FormProvider value={methods}>
            {steps[currStep].render()}
          </FormProvider>
        </div>
        <div className={styles.buttonContainer}>
          {currStep != 0 && <button type='button' className={styles.secondaryButton} onClick={handlePrev}>Back</button>}
          {steps[currStep].isStatic ? 
            <button type='button' className={styles.primaryButton} onClick={handleNext}>
              {currStep == steps.length-1 ? 'Submit' : 'Continue'}
            </button> : 
            <button type='submit' className={styles.primaryButton} form='cf'>
              {currStep == steps.length-1 ? 'Submit' : 'Continue'}
            </button>
          }
        </div>
      </div>
    </div>
  </div>);
}

function CampaignTypeForm({ id, onSubmit }) {
  const campaignTypes = [
    {
      title: campaigns.KLAVIYO.displayName, 
      description: 'Select one of your Klaviyo segments as your target audience', 
      value: campaigns.KLAVIYO.value, icon: <img src={klaviyoLogo} alt='Klaviyo logo' 
        style={{width: '1em'}} />,
    },
    {
      title: campaigns.ABD.displayName, 
      description: 'Send a follow up for customers who added item(s) to their basket but did not complete the purchase', 
      value: campaigns.ABD.value, icon: <FontAwesomeIcon icon={faShoppingCart} />,
    },
    {
      title: campaigns.NBP.displayName, 
      description: 'Follow up with customers who have recently shopped by recommending their next best purchase', 
      value: campaigns.NBP.value, icon: <FontAwesomeIcon icon={faGift} />,
    },
    {
      title: campaigns.WINBACK.displayName, 
      description: 'Reactivate lapsed customers who have not purchased in a long time', 
      value: campaigns.WINBACK.value, icon: <FontAwesomeIcon icon={faBell} />,
    },
  ];

  const { validate } = useFormContext();

  const validateAndSubmit = () => {
    if (validate({'campaign_type': {required: 'Empty!'}})) {
      onSubmit();
    }
  };

  return (
    <Form id={id} onSubmit={validateAndSubmit}>
      <TiledRadioField name='campaign_type' choices={campaignTypes} defaultValue={campaignTypes[0].value} />
    </Form>);
}

function CampaignSettingsForm({ id, onSubmit, companyOptions }) {
  const { userPermissions } = usePermissions();
  const isPPAccount = userPermissions.companyId === PAPERPLANES_ID;

  const campaignFrequencyOptions = [
    {
      title: campaignFrequency.DAILY.displayName, value: campaignFrequency.DAILY.value,
      description: 'Schedule a campaign to run daily',
    },
    {
      title: campaignFrequency.ONE_TIME.displayName, value: campaignFrequency.ONE_TIME.value,
      description: 'Run a campaign once only',
    },
  ];
  
  const methods = useFormContext();

  const validateAndSubmit = () => {
    const schema = {
      'campaign_name': {required: 'Empty!'},
      'is_one_time': {required: 'Empty!'},
    };
    if (isPPAccount) schema['company'] = {required: 'Empty!'};
    if (methods.formData['is_a_b_split']) {
      schema['variant_a_name'] = {required: 'Empty!'};
      schema['variant_b_name'] = {required: 'Empty!'};
      schema['a_b_split'] = {required: 'Empty!', min: 1, max: 99};
    }
    if (methods.validate(schema)) {
      onSubmit();
    }
  };

  return <FormProvider value={methods}>
    <Form id={id} onSubmit={validateAndSubmit}>
      {isPPAccount && <Input type='dropdown' name='company' label='Client' defaultValue='' placeholder='Select a Company' choices={companyOptions} />}
      <Input type='text' name='campaign_name' label='Campaign Name' defaultValue='' placeholder='Name of the campaign' />
      
      <div className={styles.campaignControl}>
        <div className={styles.contentHeader}>
          <b>A/B Split</b>
          <div className={styles.switchContainer}><ToggleSwitch name='is_a_b_split' defaultValue={false} /></div>
        </div>
        {methods.formData['is_a_b_split'] ? 
          <ABSplitField defaultSplit={50} /> : 
          <div>Do you want to test two versions of your campaigns to see which one performs better?</div>}
      </div>
      <div className={styles.rowContainer}>
        <label htmlFor='enable_product_recommendations'>Enable Dynamic Products</label>
        <ToggleSwitch name='enable_product_recommendations' defaultValue={false} />
      </div>
      <TiledRadioField name='is_one_time' label='Campaign Frequency' 
        choices={campaignFrequencyOptions} 
        defaultValue={campaignFrequency.DAILY.value} />
    </Form>
  </FormProvider>;
}

function LetterPathForm({ id, onSubmit, letterPaths }) {
  const { formData, validate } = useFormContext();

  const validateAndSubmit = () => {
    const schema = {
      'letter_path_a': {required: 'Empty!'},
    };
    if (formData['is_a_b_split']) schema['letter_path_b'] = {required: 'Empty!'};
    if (validate(schema)) {
      onSubmit();
    }
  };

  return (<Form id={id} onSubmit={validateAndSubmit}>
    <Input type='dropdown' name='letter_path_a' placeholder='Select A Letter'
      label={formData['is_a_b_split'] ? 'Variant A Letter' : ''} choices={letterPaths} />
    {formData['is_a_b_split'] && <Input type='dropdown' name='letter_path_b' placeholder='Select A Letter'
      label='Variant B Letter' choices={letterPaths} />}
  </Form>);
}

function EnvelopePathForm({ id, onSubmit, envelopePaths }) {
  const { formData, validate } = useFormContext();

  const validateAndSubmit = () => {
    const schema = {
      'envelope_path_a': {required: 'Empty!'},
    };
    if (formData['is_a_b_split']) schema['envelope_path_b'] = {required: 'Empty!'};
    if (validate(schema)) {
      onSubmit();
    }
  };
  
  return (<Form id={id} onSubmit={validateAndSubmit}>
    <Input type='dropdown' name='envelope_path_a' placeholder='Select An Envelope'
      label={formData['is_a_b_split'] ? 'Variant A Envelope' : ''} choices={envelopePaths} />
    {formData['is_a_b_split'] && <Input type='dropdown' name='envelope_path_b' placeholder='Select An Envelope'
      label='Variant B Envelope' choices={envelopePaths} />}
  </Form>);
}

function CampaignControlsForm({ id, onSubmit }) {
  const { formData, validate } = useFormContext();

  const validateAndSubmit = () => {
    const schema = {
      'suppression_period': formData['suppression_enabled'] ? {
        required: 'Empty!', min: 0, max: 365,
      } : {},
      'control_split': formData['control_split_enabled'] ? {
        required: 'Empty!', min: 0, max: 99,
      } : {},
      'daily_send_limit': formData['send_limit_enabled'] ? {
        required: 'Empty!', min: 1,
      } : {},
    };

    if (validate(schema)) {
      onSubmit();
    }
  };
  
  return (<Form id={id} onSubmit={validateAndSubmit}>
    <div className={styles.campaignControl}>
      <div className={styles.contentHeader}>
        <b>Suppression</b>
        <div className={styles.switchContainer}><ToggleSwitch name='suppression_enabled' defaultValue={true} /></div>
      </div>
      {formData['suppression_enabled'] ? 
        <div className={styles.content}>
          <span>Send your campaign to a customer a maximum of once every</span>
          <div className={styles.numberContainer}>
            <Input type='number' name='suppression_period' defaultValue={30} min={0} max={365} />
          </div>
          <span>days</span>
        </div> : 
        <div>No suppression enabled</div>}
    </div>

    <div className={styles.campaignControl}>
      <div className={styles.contentHeader}>
        <b>Control Split</b>
        <div className={styles.switchContainer}><ToggleSwitch name='control_split_enabled' defaultValue={true} /></div>
      </div>
      {formData['control_split_enabled'] ? 
        <div className={styles.content}>
          <span>Hold back</span>
          <div className={styles.numberContainer}>
            <Input type='number' name='control_split' defaultValue={15} min={1} max={99} />
          </div>
          <span>% of customers for a control group</span>
        </div> : 
        <div>No control split</div>}
    </div>

    <div className={styles.campaignControl}>
      <div className={styles.contentHeader}>
        <b>Daily Send Limit</b>
        <div className={styles.switchContainer}><ToggleSwitch name='send_limit_enabled' defaultValue={false} /></div>
      </div>
      {formData['send_limit_enabled'] ? 
        <div className={styles.content}>
          <span>Send a maximum of</span>
          <div className={styles.longNumberContainer}>
            <Input type='number' name='daily_send_limit' defaultValue={9999} min={0} />
          </div>
          <span>letters per day</span>
        </div> : 
        <div>No limit on sends per day</div>}
    </div>
  </Form>);
}

export default CreateCampaign;
