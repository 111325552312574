import React from 'react';

import styles from './ToggleSwitch.module.scss';
import { useFormContext } from '../../hooks/useForm';

function ToggleSwitch({ name, defaultValue }) {
  const { formData, register, setValue } = useFormContext();
  return (<>
    <input type='checkbox' id={name} name={name} className={styles.switch}
      {...register(name, defaultValue)}
      checked={formData[name] === undefined ? defaultValue : formData[name]}
      onChange={(e) => setValue(name, e.target.checked)} />
    <label htmlFor={name} className={styles.slider}></label>
  </>);
}

export default ToggleSwitch;
