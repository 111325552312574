import ApiService from './ApiService';

const CustomerService = {
  getBlacklistedEmails: async (id) => {
    return await ApiService.get(`/companies/${id}/blacklist/email`);
  },
  blacklistEmail: async (id, email) => {
    return await ApiService.post(`/companies/${id}/blacklist/email`, {email});
  },
  getBlacklistedPostcodes: async (id) => {
    return await ApiService.get(`/companies/${id}/blacklist/postcode`);
  },
  blacklistPostcode: async (id, postcode) => {
    return await ApiService.post(`/companies/${id}/blacklist/postcode`, {postcode});
  },
};

export default CustomerService;
