import React from 'react';

import styles from './TiledRadioField.module.scss';
import Input from '../Form/Input';

function TiledRadioField({ name, label, choices, defaultValue }) {
  const formattedChoices = choices.map((c) => ({
    label: <div className={styles.labelContainer}>
      {c.icon && <div className={styles.iconContainer}>{c.icon}</div>}
      <div>
        <div className={styles.title}>{c.title}</div>
        <div className={styles.description}>{c.description}</div>
      </div>
    </div>,
    value: c.value,
  }));
  return <Input name={name} type='radio' label={label} choices={formattedChoices} defaultValue={defaultValue} className={styles.radioTile} />;
}

export default TiledRadioField;
