import ApiService from './ApiService';
import { formatCampaignToFlat, formatCampaignToNested } from './utils/format_campaigns';

const CampaignService = {
  getCampaignList: async () => {
    const data = await ApiService.get('/campaigns/');
    return formatCampaignToFlat(data);
  },
  createCampaign: async (data) => {
    data = formatCampaignToNested(data);
    return await ApiService.post('/campaigns/', data);
  },
  createCampaignDraft: async ({ data }) => {
    // For the future
    // return await ApiService.post('/campaignDrafts/', body=data);
    return { success: true};
  },
  deleteCampaign: async (campaignId) => {
    return await ApiService.delete(`/campaigns/${campaignId}/`);
  },
  changeCampaignStatus: async (campaignId, data) => {
    if (data.hasOwnProperty('client')) delete data['client'];
    data = formatCampaignToNested(data);
    return await ApiService.patch(`/campaigns/${campaignId}/`, data);
  },
  editCampaign: async (campaignId, data) => {
    if (data.hasOwnProperty('client')) delete data['client'];
    data = formatCampaignToNested(data);
    return await ApiService.patch(`/campaigns/${campaignId}/`, data);
  },
  getMailableCustomers: async (companyId, listId) => {
    const response = await ApiService.get(
        `/data_sources/klaviyo/${companyId}/customer_count/${listId}/`);
    if (response.hasOwnProperty('status')) return { success: false };
    console.log(response);
    return { success: true, count: response.data.count };
  },
};

export default CampaignService;
